import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './player.css';
import './range-player.css';


const DEFAULT_PODCAST = {
    title: 'Cargando....',
    category: '',

}

const  LIMIT_STR = 20;

class Player extends Component {

    constructor(props){
        super(props);
        this.state = {
            duration: 0,
            time: 0,
            playing: false,
            volume: 0.5,
            seeking: false,
            mute: false,
            old_volume: 0.5,
            podcasts: [],
            idx: 0,
            loading: false,
        };

        this.player_ref = React.createRef();
    }

    componentDidMount() {
        this.getPodcasts();
    }

    getPodcasts() {
        this.setState({ loading: true });
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_BASE}/podcasts`,
            headers: { 
                        "content-type": "application/json",
                      },
        })
        .then(result => {
            console.log(result);
            if (result.data.status === 'success'){
                this.setState({ podcasts: result.data.podcasts, loading: false });
            }
        })
        .catch(error => {
            console.log('error',error);
            this.setState({ error: error.message, loading: false });
        });
    }

    seekForward(e){
        e.preventDefault();
        e.stopPropagation();
        if (this.player_ref){
            const seek = this.props.seekSeconds || 5;
            const new_time = this.state.time+seek;
            this.player_ref.current.seekTo(new_time);
            this.setState({ time: new_time });
        }
    }

    seekBackward(e){
        e.preventDefault();
        e.stopPropagation();
        if (this.player_ref){
            const seek = this.props.seekSeconds || 5;
            let new_time = this.state.time-seek;
            new_time = new_time > 0 ? new_time : 0;
            this.player_ref.current.seekTo(new_time);
            this.setState({ time: new_time });
        }
    }

    seekTo(e) {
        if (this.player_ref){
            this.player_ref.current.seekTo(e.target.value);
            this.setState({ time: e.target.value });
        }
    }

    nextPodcast(){
        const { idx } = this.state;
        if (this.state.podcasts.length > 0) {
            const pd = (idx + 1) % this.state.podcasts.length;
            this.setState({ time: 0, duration: 'undefined', playing: true, idx: pd });
        }
    }

    prevPodcast(){
        const { idx } = this.state;
        if(this.state.podcasts.length > 0) {
            this.setState({ time: 0, duration: 'undefined', playing: true, idx: idx === 0 ? this.state.podcasts.length - 1 : idx - 1 });
        }
    }

    mute = () => {
        const { mute, old_volume, volume } = this.state;
        this.setState({ mute: !mute, volume: mute ? old_volume : 0.0, old_volume: mute ? old_volume : volume });
    }
    
    highVolume = () => {
        const { mute, old_volume, volume } = this.state;
        const newvolume = mute ? old_volume + 0.1 : volume + 0.1;
        this.setState({ mute: false, volume: newvolume > 1.0 ? 1.0 : newvolume });
    }


    render() {
        const { duration, time, playing, volume, mute, idx, podcasts } = this.state;
        const item = podcasts.length > 0 ? podcasts[idx] : DEFAULT_PODCAST;
        const stimate = duration || item.duration;
        return (
            <div className="player-zone">
                <div className="title-player">
                    {item.img ? 
                        (<img src={item.img} alt="img"></img>) : 
                        (<img src="/img/default.png" alt="img"></img>)
                    }
                    {this.state.loading ? (
                        <Loader visible={this.state.loading } type="Oval" color="#E6750A" height={48} width={48}/>
                    ) : (
                        <React.Fragment>
                            <h2 className="title-podcasts">{item.title}</h2>
                            <h4 className="subtitle-podcasts">{item.author}</h4>
                        </React.Fragment>
                    )}
                </div>
                <ReactPlayer 
                    key={'id'}
                    ref={this.player_ref}
                    preload={''}
                    url={item.url}
                    volume={volume}
                    muted={mute}
                    playing={playing}
                    style={{ display: 'none' }}
                    onEnded={() => { this.setState({ playing: false }); } }
                    onDuration={(d) => { this.setState({ duration: d }) }}
                    onProgress={(state) => { this.setState({ time: Math.ceil(state.playedSeconds) }); }}
                    onError={(error) => { console.log(error) }}
                />
                <div className="control-player">
                    <div className="track-player">
                        <input type="range" value={time} step={1} max={duration || 0} onChange={this.seekTo.bind(this)}></input>
                    </div>
                    <div className="info-time">
                        <label className="palyer-time">{secondsToHHMMSS(time)}/{secondsToHHMMSS(stimate)}</label>
                        <label className="palyer-time right">Podcast {item.category.length > LIMIT_STR ? item.category.substr(0,LIMIT_STR)+'...' : item.category}</label>
                    </div>
                    <div className="more-controls">
                        <div className="row">
                            <div className="col-md-4 col-xs-12">
                                <div className="volumen-controls">
                                    <img src={"/img/icons/mudo-white.svg"} style={{ width: 12, height:20, marginRight: 10 }} alt="mute" onClick={this.mute.bind(this)} />
                                    <input type="range" min={0} max={1} step={0.01} value={volume} onChange={(e) => { this.setState({ volume: parseFloat(e.target.value) }) }} />
                                    <img src={"/img/icons/volumen-white.svg"} style={{ width: 12, height:20,  marginLeft: 10 }} alt="mute" onClick={this.highVolume.bind(this)} />
                                </div>
                            </div>
                            <div className="col-md-4 col-xs-12">
                            <div className="play-controls">
                                <img 
                                    src={"/img/icons/prev.svg"} 
                                    alt="prev"
                                    onClick={this.prevPodcast.bind(this)}
                                />
                                <img
                                    style={{ width: 40, height: 40 }}
                                    src={playing ? "/img/icons/pausa.svg" : "/img/icons/play-podcast.svg"}  
                                    alt="play" 
                                    onClick={() => { this.setState(prev => ({ playing: !prev.playing })) }}
                                />
                                <img 
                                    src={"/img/icons/next.svg"} 
                                    alt="next" 
                                    onClick={this.nextPodcast.bind(this)}
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="prox-podcasts">
                    <h4>Proximamente otros podcasts</h4>
                </div>
            </div>
        )
    }

}

function secondsToHHMMSS(t) {
    if (t === undefined || isNaN(t)) return '-:--'
    var h = Math.floor(t / 3600)
    var m = Math.floor(t / 60) % 60
    var s = Math.floor(t % 60)
    return `${h ? h + ':' : ''}${h ? ('0' + m).slice(-2) : m}:${('0' + s).slice(
      -2
    )}`
  }

export default Player;