import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Progress from 'react-progress-2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-progress-2/main.css';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { createRequest } from '../../redux/actions';
import { BACKEND, requestIds } from '../../redux/constants/backend';
import ProgressBar from '../../components/Progress/progress';
import '../Admin/admin.css';

class Programing extends Component {
    
    state = {
        email: '',
        password: '',
        loading: false,
        show: false,
        podcasts: [],
        programation: [],
        file: null,
        duration: undefined,
        imagePreview: null,
        fileImg: null,
        title: '',
        position: 0,
        progress: 0,
        author: '',
        serie: '',
        loadingAudio: false,
        loadingPodcast: false,
        loadingEdit: false,
        showE: false,
        editing: { value: 0, id: null },
    }

    img = React.createRef();
    img_e = React.createRef();

    componentDidMount() {
        document.title = 'Programación | Circuito Líder';
        this.getProgramation();
    }

    getProgramation() {
        this.setState({ loading: true });
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_BASE}/programation`,
            headers: { 
                        "content-type": "application/json",
                      },
        })
        .then(result => {
            if (result.data.status === 'success'){
                this.setState({ programation: result.data.programation, loading: false });
            }
        })
        .catch(error => {
            this.setState({ error: error.message, loading: false });
        });
    }

    async addProgramation() {
        const { fileImg, title, position } = this.state;

        let error = false;
        if(title.trim() === '') {
            toast.error('Ingrese un titulo', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true;
        }

        if(!Number.isInteger(position)) {
            toast.error('Ingrese una posición en el banner', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true;
        } else if(position > this.state.programation.length + 1) {
            toast.error('Ingrese una posición valida en el banner', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true; 
        }

        if (!fileImg) {
            toast.error('Seleccione una imagen', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true;
        }  
        if (error) {
            this.setState({ loadingPodcast: false });
            return;
        }
        this.setState({ loadingPodcast: true, progress: 0 });
        const data = new FormData();
        data.append('title',title);
        data.append('position',position);
        data.append('fileImg',fileImg);
        Progress.show();
        try {
            const resp = await this.props.createRequest({
                requestParams: {
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE}/${BACKEND.ADD_PROGRAMATION}`,
                    data: data,
                    onUploadProgress: (progressEvent) => { 
                        let completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        this.setState({ progress: completed });
                    },
                },
                requestId: requestIds.ADD_PROGRAMATION,
            },true);
            if(resp.data.status === 'success') {
                let programation = resp.data.programation;
                this.setState(prevState => ({ programation: programation, show: false }));
                toast.success('Programación agregada', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  });
            } else {
                let field = null;
                const { errors } = resp.data;
                for (field in errors) {
                    const errorF = errors[field].join('<br>');
                    toast.error(errorF, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000
                      });
                }
            }
        } catch(error) {
            toast.error('Error en el servidor, intente más tarde', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
        Progress.hide();
        this.setState({ loadingPodcast: false });

    }

    async deleteProgramation(podcast) {
        Progress.show();
        try {
            const resp = await this.props.createRequest({
                requestParams: {
                  method: 'post',
                  url: `${process.env.REACT_APP_API_BASE}/${BACKEND.DELETE_PROGRAMATION}`,
                  data: { id: podcast.id },
                },
                requestId: requestIds.DELETE_PROGRAMATION,
            },true);
            if(resp.data.status === 'success') {
                let programation = resp.data.programation;
                this.setState(prevState => ({ programation: programation, show: false }));
                toast.success('Programación Eliminada', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  });
            } else {
                let field = null;
                const { errors } = resp.data;
                for (field in errors) {
                    const errorF = errors[field].join('<br>');
                    toast.error(errorF, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000
                      });
                }
            }
        } catch(error) {
            toast.error('Error en el servidor, intente más tarde', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
        Progress.hide();
    }

    async editNews(e) {
        e.preventDefault();
        const { editing } = this.state;
        const { value } = editing;

        if(!Number.isInteger(value)) {
            toast.error('Ingrese una posición en el banner', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            return;
        } else if(value > this.state.programation.length + 1) {
            toast.error('Ingrese una posición valida en el banner', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            return;
        }

        this.setState({ loadingEdit: true, });
        Progress.show();
        try {
            const resp = await this.props.createRequest({
                requestParams: {
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE}/${BACKEND.EDIT_PROGRAMATION}/${editing.id}`,
                    data: { position: value },
                },
                requestId: requestIds.EDIT_PROGRAMATION,
            },true);
            if(resp.data.status === 'success') {
                let programation = resp.data.programation;
                this.setState({ programation: programation, editing: { id: null, value: 0 } });
                toast.success('Programación agregada', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  });
            } else {
                let field = null;
                const { errors } = resp.data;
                for (field in errors) {
                    const errorF = errors[field].join('<br>');
                    toast.error(errorF, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000
                      });
                }
            }
        } catch(error) {
            toast.error('Error en el servidor, intente más tarde', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
        Progress.hide();
        this.setState({ loadingEdit: false });
    }

    onDrop(files) {
        const file = files[0];
        if(!file.type.includes('image/')) {
            toast.error("Tipo de archivo invalido, solo se permiten formatos de imagen",{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            return;
        }

        let reader = new FileReader();
        const that = this;
        reader.onloadend = () => {
                that.setState({
                    imagePreview: reader.result,
                    fileImg: file,
                    title: file.name,
                  });
        };
        reader.readAsDataURL(file);
    }

    openModal() {
        this.setState({ 
                        file: null,
                        imagePreview: null,
                        fileImg: null,
                        title: '',
                        author: '',
                        position: this.state.programation.length+1,
                        show: true,
                    });
    }

    showModal() {
        const { show, imagePreview, title, position, loadingPodcast, progress } = this.state;
        if (show) {
            return (
                <div className="modal fade show" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: show ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="stepper">
                                    <h2 className="contact-title" style={{ width: '50%' }}>Seleccionar Imagen</h2>
                                </div>
                            </div>
                            <div className="modal-body" style={{ overflowY: 'scroll', maxHeight: 400 }}>
                                <div>
                                    
                                        <Dropzone onDrop={this.onDrop.bind(this)}>
                                                {({getRootProps, getInputProps}) => (
                                                <section className="container">
                                                    <div {...getRootProps({className: 'dropzone'})}>
                                                        <input {...getInputProps()} />
                                                        {imagePreview ? (
                                                            <div className="imagen-selected">
                                                                <img src={imagePreview} alt="Seleccione" className="img-fluid"></img>
                                                            </div>
                                                        ) : (
                                                        <p>Arrastra una imagen, o has click para seleccionar un archivo</p>
                                                        )}
                                                        {loadingPodcast && (
                                                            <ProgressBar progress={progress} />
                                                        )}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    <div className="row">
                                        <div className="col-md-10 col-xs-12 mx-auto">
                                            <form className="form form-input">
                                                <div className="form-group">
                                                    <label>Titulo</label>
                                                    <input className="form-control" value={title} onChange={(e) => { this.setState({ title: e.target.value }) }}></input>
                                                </div>
                                                <div className="form-group">
                                                    <label>Posición</label>
                                                    <input type="number" min={1} max={this.state.programation.length+1} className="form-control" value={position} onChange={(e) => { this.setState({ position: parseInt(e.target.value) }) }}></input>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { this.setState({ show: false }) }} disabled={loadingPodcast}>Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={this.addProgramation.bind(this)} disabled={loadingPodcast}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    async logout(e) {
        e.preventDefault();
        e.stopPropagation();
        Progress.show();
        try {
            await this.props.createRequest({
                requestParams: {
                  method: 'get',
                  url: `${process.env.REACT_APP_API_BASE}/${BACKEND.LOGOUT}`,
                },
                requestId: requestIds.LOGOUT,
            },true);
        } catch(error) {
          console.log('');
        } finally {
            localStorage.clear();
            Progress.hide();
            this.props.history.replace('/');
        }
    }


    render() {
        const { programation, editing, loadingEdit, loading } = this.state;
        return (
            <div className="App" style={{ overflow: 'hidden' }}>
                <React.Fragment>
                    <header className="header">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                    <ul className="navbar-nav mr-auto">
                                        <NavLink to="/"><img src="/img/CIRCUITO-LIDER.png" alt="" className="img-fluid nav-logo" /></NavLink>
                                    </ul>
                                    <ul className="navbar-nav ml-auto">
                                        <li className="link-nav">
                                            <a href="#logout" onClick={this.logout.bind(this)}><i className="fa fa-user"></i>salir</a>
                                        </li>
                                    </ul>
                            </nav>
                        </div>
                    </header>
                    <ToastContainer autoClose={false} limit={4} />
                    <section className="header-section section-padding">
                        <div className="container-fluid">
                        <div className="row mx-auto">
                            <div style={{ width: '100%' }}>
                            <NavLink className="link-nav" to="/programacion" >Programación</NavLink>
                            <NavLink className="link-nav" to="/admin" >Podcast</NavLink>
                            <NavLink className="link-nav" to="/novedades">Novedades</NavLink>
                            </div>
                        </div>
                        </div>
                    </section>
                    <div className="section-padding section-podcasts text-center">
                        <h2 className="contact-title">Programación</h2>
                    </div>

                    <section className="section-padding">
                        <div className="row">
                            <div className="col-md-10 mx-auto" style={{ textAlign: 'left' }}>
                                <button className="btn btn-lg btn-add" onClick={this.openModal.bind(this)}>Agregar Nuevo</button>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-md-10 col-xs-12 mx-auto">
                                <Loader visible={loading} type="Oval" color="#E6750A" height={48} width={48}/>
                                <table className="table table-stripe table-podcasts">
                                    <tbody>
                                        {programation.map((pr,i) => (
                                            <tr key={pr.id}>
                                                    <td>
                                                    {editing.id === pr.id ? 
                                                      (<input style={{ width: 50 }} type="number" value={editing.value} min={1} max={programation.length} onChange={(e) => { this.setState({ editing: { ...editing, value: parseInt(e.target.value) } }) }}></input>) 
                                                    : (<label className="podcast-item-author"> {i+1}</label>)
                                                    }
                                                    </td>
                                                    <td><img src={pr.url} alt="podcast" className="podcast-img"></img></td>
                                                    <td style={{ textAlign: 'left' }}><label className="podcast-item-title">{pr.name}</label></td>
                                                    <td>
                                                        {editing.id === pr.id ? (
                                                            <button className="btn btn-sm btn-primary" onClick={this.editNews.bind(this)} disabled={loadingEdit}><i className="fa fa-save"></i></button>
                                                        ) : (
                                                            <button className="btn btn-sm btn-primary" onClick={() => { this.setState({ editing: { id: pr.id, value: i+1 } }) }} disabled={loadingEdit}><i className="fa fa-edit"></i></button>
                                                        )}
                                                        <button className="btn btn-sm btn-danger" title="Eliminar" onClick={() => { this.deleteProgramation(pr) }} disabled={loadingEdit}><i className="fa fa-trash-o"></i></button>
                                                    </td>
                                            </tr> 
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                    {this.showModal()}
                </React.Fragment>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
    }
}

const mapDispatchToProps = {
    createRequest
};
export default connect(mapStateToProps, mapDispatchToProps)(Programing);