export const BACKEND = {
    CREATE_PODCAST: 'podcasts/create',
    EDIT_PODCAST: 'podcasts/edit',
    DELETE_PODCAST: 'podcasts/delete',
    ADD_PROGRAMATION: 'programation/create',
    DELETE_PROGRAMATION: 'programation/delete',
    EDIT_PROGRAMATION: 'programation/edit',
    ADD_NEWS: 'news/create',
    DELETE_NEWS: 'news/delete',
    EDIT_NEWS: 'news/edit',
    LOGOUT: 'logout',
};

export const requestIds = {
    CREATE_PODCAST: 'api_request_create_podcast',
    EDIT_PODCAST: 'api_request_edit_podcast',
    DELETE_PODCAST: 'api_request_delete_podcast',
    ADD_PROGRAMATION: 'api_request_programation',
    DELETE_PROGRAMATION: 'api_request_delete_programation',
    EDIT_PROGRAMATION: 'api_request_programation',
    DELETE_NEWS: 'api_request_delete_news',
    EDIT_NEWS: 'api_request_edit_news',
    LOGOUT: 'api_request_logout',
};

export const API_REQUEST_STARTED = 'api_request_started';
export const API_REQUEST_SUCCEEDED = 'api_request_succeeded';
export const API_REQUEST_FAILED = 'api_request_failed';
export const API_REQUEST_CANCEL_REQUEST = 'api_request_cancel_request';