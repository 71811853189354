import React from 'react';
import { Route, BrowserRouter, Switch} from 'react-router-dom';
import { Provider, } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import Progress from 'react-progress-2';

import { PrivateRoute, RedirectIfAuthRoute } from './components/ProtectedRoute';
import reducers from './redux/reducers';

import { setSessionAuth } from './redux/actions';

import Home from './containers/Home/home';
import Login from './containers/Login/login';
import Admin from './containers/Admin/admin';
import Programing from './containers/Programing/programing';
import News from './containers/News/news';
import GenericNotFound from './containers/GenericNotFound/genericNotFound';

const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

class App extends React.Component {

  constructor(props) {
    super(props);
    const auth = localStorage.getItem('auth_user');
    console.log(auth);
    if (auth) {
      store.dispatch(setSessionAuth({ isAuthenticated: true, ...JSON.parse(auth)}));
    }
  }

  render() {
    return (
        <Provider store={store}>
          <BrowserRouter>
            <React.Fragment>
              <Progress.Component
                  style={{ background: '#99999978', height: '5px' }}
                  thumbStyle={{ background: '#ffe447', height: '5px' }}
              />
              <Switch>
                <Route exact path="/" component={Home} />
                <RedirectIfAuthRoute exact path="/login" component={Login} redirect_to="/admin" />
                <PrivateRoute path='/admin' component={Admin} />
                <PrivateRoute path='/programacion' component={Programing} />
                <PrivateRoute path='/novedades' component={News} />
                <Route component={GenericNotFound} />
              </Switch>
            </React.Fragment>
          </BrowserRouter>
        </Provider>
    );
  }
}

export default App;