import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Progress from 'react-progress-2';
import ReactPlayer from "react-player";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-progress-2/main.css';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ProgressBar from '../../components/Progress/progress';
import { createRequest } from '../../redux/actions';
import { BACKEND, requestIds } from '../../redux/constants/backend';
import './admin.css';

class Admin extends Component {
    
    state = {
        email: '',
        password: '',
        loading: false,
        show: false,
        podcasts: [],
        file: null,
        filesrc: null,
        duration: undefined,
        imagePreview: null,
        fileImg: null,
        title: '',
        author: '',
        serie: '',
        loadingAudio: false,
        loadingPodcast: false,
        showE: false,
        editing: {},
        completed: 0,
        enter: '',
    }

    img = React.createRef();
    img_e = React.createRef();

    componentDidMount() {
        document.title = 'Admin | Circuito Líder';
        this.getPodcasts();
    }

    getPodcasts() {
        this.setState({ loading: true });
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_BASE}/podcasts`,
            headers: { 
                        "content-type": "application/json",
                      },
        })
        .then(result => {
            if (result.data.status === 'success'){
                this.setState({ podcasts: result.data.podcasts, loading: false });
            }
        })
        .catch(error => {
            this.setState({ error: error.message, loading: false });
        });
    }

    async createPodcast() {
        const { file, fileImg, title, author, serie, duration } = this.state;
        this.setState({ loadingPodcast: true });
        let error = false;
        if(title.trim() === '') {
            toast.error('Ingrese un titulo', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true;
        }

        if(author.trim() === '') {
            toast.error('Ingrese Autor(es)', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true;
        }

        if(serie.trim() === '') {
            toast.error('Ingrese Categoría o Progama al cual pertenece el podcast', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true;
        }
        if (file === null) {
            toast.error('Debe seleccionar un audio valido', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            error = true;
        }
        if (error) {
            this.setState({ loadingPodcast: false });
            return;
        }
        const data = new FormData();
        data.append('title',title);
        data.append('author',author);
        data.append('category',serie);
        data.append('audio',file);
        data.append('duration',duration);
        data.append('fileImg',fileImg);
        Progress.show();
        try {
            const resp = await this.props.createRequest({
                requestParams: {
                  method: 'post',
                  url: `${process.env.REACT_APP_API_BASE}/${BACKEND.CREATE_PODCAST}`,
                  data: data,
                },
                requestId: requestIds.CREATE_PODCAST,
            },true);
            if(resp.data.status === 'success') {
                let podcast = resp.data.podcast;
                URL.revokeObjectURL(this.state.filesrc);
                this.setState(prevState => ({ podcasts: [...prevState.podcasts, podcast], show: false, filesrc: null }));
                toast.success('Podcast Creado', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  });
                
            } else {
                let field = null;
                const { errors } = resp.data;
                for (field in errors) {
                    const errorF = errors[field].join('<br>');
                    toast.error(errorF, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000
                      });
                }
            }
        } catch(error) {
            toast.error('Error en el servidor, intente más tarde', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
        Progress.hide();
        this.setState({ loadingPodcast: false });

    }

    async editPodcast() {
        const { editing } = this.state;
        const { id, fileImg, title, author, category } = editing;
        this.setState({ loadingEditPodcast: true });
        let error = false;
        if(title.trim() === '') {
            toast.error('Ingrese un titulo', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true;
        }

        if(author.trim() === '') {
            toast.error('Ingrese Autor(es)', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true;
        }

        if(category.trim() === '') {
            toast.error('Ingrese Categoría o Progama al cual pertenece el podcast', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            error = true;
        }

        if (error) {
            this.setState({ loadingEditPodcast: false });
            return;
        }
        const data = new FormData();
        data.append('title',title);
        data.append('author',author);
        data.append('category',category);
        if (fileImg)
            data.append('fileImg',fileImg);
        Progress.show();
        try {
            const resp = await this.props.createRequest({
                requestParams: {
                    method: 'post',
                    url: `${process.env.REACT_APP_API_BASE}/${BACKEND.EDIT_PODCAST}/${id}`,
                     data: data,
                    onUploadProgress: (progressEvent) => { 
                        let completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        this.setState({ progress: completed });
                    },
                },
                requestId: requestIds.EDIT_PODCAST,
            },true);
            if(resp.data.status === 'success') {
                let podcast = resp.data.podcast;
                this.setState(prevState => ({ podcasts: prevState.podcasts.map(p => (p.id === id ? podcast : p)), showE: false }));
                toast.success('Podcast Editado', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  });
            } else {
                let field = null;
                const { errors } = resp.data;
                for (field in errors) {
                    const errorF = errors[field].join('<br>');
                    toast.error(errorF, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000
                      });
                }
            }
        } catch(error) {
            toast.error('Error en el servidor, intente más tarde', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
        Progress.hide();
        this.setState({ loadingEditPodcast: false });

    }

    async deletePodcast(podcast) {
        Progress.show();
        try {
            const resp = await this.props.createRequest({
                requestParams: {
                  method: 'post',
                  url: `${process.env.REACT_APP_API_BASE}/${BACKEND.DELETE_PODCAST}`,
                  data: { id: podcast.id },
                },
                requestId: requestIds.DELETE_PODCAST,
            },true);
            if(resp.data.status === 'success') {
                this.setState(prevState => ({ podcasts: prevState.podcasts.filter(p => (p.id !== podcast.id)) }));
                toast.success('Podcast Eliminado', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  });
            } else {
                let field = null;
                const { errors } = resp.data;
                for (field in errors) {
                    const errorF = errors[field].join('<br>');
                    toast.error(errorF, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000
                      });
                }
            }
        } catch(error) {
            toast.error('Error en el servidor, intente más tarde', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
        Progress.hide();

    }

    onDrop(files) {
        const file = files[0];
        if(!file.type.includes('audio/')) {
            toast.error("Tipo de archivo invalido, solo se permiten formatos .mp3 o .wav",{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            return;
        }
        if (this.state.filesrc) {
            URL.revokeObjectURL(this.state.filesrc);
        }

        const url_a = URL.createObjectURL(file);
        this.setState({ loadingAudio: true, enter:'', filesrc: url_a, file, name: file.name  });

        /*let reader = new FileReader();
        const that = this;
        reader.onload = function (event) {
            // Create an instance of AudioContext
            let audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
            // Asynchronously decode audio file data contained in an ArrayBuffer.
            audioContext.decodeAudioData(event.target.result, function(buffer) {
                // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
                let duration = buffer.duration;
                that.setState({ name: file.name, duration: Math.floor(duration), loadingAudio: false, file });
                // example 12.3234 seconds
                // Alternatively, just display the integer value with
                // parseInt(duration)
                // 12 seconds
            });
        };
    
        // In case that the file couldn't be read
        reader.onerror = function (event) {
            that.setState({ loadingAudio: false });
        };
    
        // Read file as an ArrayBuffer, important !
        reader.readAsArrayBuffer(file);*/
    }

    selectImag (files, edit = false) {
        const file = files[0];
        if(!file.type.includes('image/')) {
            toast.error("Tipo de archivo invalido, solo se permiten formatos .png, .jpg, .gif");
            return;
        }

        let reader = new FileReader();
        const that = this;
        reader.onloadend = () => {
            if (edit) {
                that.setState({
                    editing: {
                        ...that.state.editing,
                        imagePreview: reader.result,
                        fileImg: file
                    }
                });
            } else {
                that.setState({
                    imagePreview: reader.result,
                    fileImg: file
                  });
            }
        };
        reader.readAsDataURL(file);
    }

    openModal() {
        if (this.state.filesrc) {
            URL.revokeObjectURL(this.state.filesrc);
        }
        this.setState({ 
                        file: null,
                        filesrc: null,
                        imagePreview: null,
                        fileImg: null,
                        title: '',
                        name: '',
                        author: '',
                        serie: '',
                        show: true,
                        duration: undefined,
                    });
    }

    showModal() {
        const { show, name, duration, imagePreview, title, author, serie, loadingPodcast, progress } = this.state;
        if (show) {
            return (
                <div className="modal fade show" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: show ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="stepper">
                                    <h2 className="contact-title" style={{ width: '50%' }}>Selecciona Audio</h2>
                                </div>
                            </div>
                            <div className="modal-body" style={{ overflowY: 'scroll', maxHeight: 400 }}>
                                <div>
                                    <Dropzone 
                                        onDrop={this.onDrop.bind(this)}
                                        onDragEnter={() => { this.setState({ enter: 'dropzone-enter' }) }}
                                        onDragLeave={() => { this.setState({ enter: '' }) }}
                                    >
                                            {({getRootProps, getInputProps}) => (
                                            <section className="container">
                                                <div {...getRootProps({className: 'dropzone '+this.state.enter})}>
                                                    <input {...getInputProps()} />
                                                    <p>Arrastra un archivo de audio, o has click para seleccionar un archivo</p>
                                                    {loadingPodcast && (
                                                        <ProgressBar progress={progress} />
                                                    )}
                                                </div>
                                            </section>
                                            )}
                                    </Dropzone>
                                    <ReactPlayer
                                        url={this.state.filesrc}
                                        style={{ display: 'none' }}
                                        controls = {false}
                                        onDuration={(d) => { this.setState(prevState => ({ duration: d, loadingAudio: false })) }}
                                    />
                                    <div className="row info-audio">
                                        <div className="col-md-10 col-xs-12 mx-auto">
                                            <div style={{ textAlign: 'left' }}>
                                                <label className="field-name">Archivo: </label>
                                                <label className="field-name-data">{this.state.loadingAudio ? 'Cargando...' : name}</label>
                                            </div>
                                            <div style={{ textAlign: 'left' }}>
                                                <label className="field-name">Duración: </label>
                                                <label className="field-name-data"> {secondsToHHMMSS(duration)}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10 col-xs-12 mx-auto">
                                            <form className="form form-input">
                                                <div className="form-group">
                                                    <label>Titulo</label>
                                                    <input className="form-control" value={title} onChange={(e) => { this.setState({ title: e.target.value }) }}></input>
                                                </div>
                                                <div className="form-group">
                                                    <label>Autor(es)</label>
                                                    <input className="form-control" value={author} onChange={(e) => { this.setState({ author: e.target.value }) }}></input>
                                                </div>
                                                <div className="form-group">
                                                    <label>Serie/Programa</label>
                                                    <input className="form-control" value={serie} onChange={(e) => { this.setState({ serie: e.target.value }) }}></input>
                                                </div>
                                                <div className="form-group">
                                                    <label>Imagen (Menor a 300px)</label>
                                                    <label for="img-p" style={{ display: 'block' }}>
                                                        <div className="imagen-select">
                                                            <img src={imagePreview} alt="Seleccione" className="img-fluid"></img>
                                                        </div>
                                                    </label>
                                                    <input id="img-p" style={{ display: 'none' }} ref={this.img} type='file' className="form-control" onChange={(e) => { this.selectImag(e.target.files); }}></input>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { this.setState({ show: false }) }} disabled={loadingPodcast}>Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={this.createPodcast.bind(this)} disabled={loadingPodcast}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    showModalEdit() {
        const { showE, editing, loadingEditPodcast } = this.state;
        const { imagePreview, title, author, category, img } = editing;
        if (showE) {
            return (
                <div className="modal fade show" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: showE ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="stepper">
                                    <h2 className="contact-title" style={{ width: '50%' }}>Editar Podcast</h2>
                                </div>
                            </div>
                            <div className="modal-body" style={{ overflowY: 'scroll', maxHeight: 400 }}>
                                <div>
                                    <div className="row">
                                        <div className="col-md-10 col-xs-12 mx-auto">
                                            <form className="form form-input">
                                                <div className="form-group">
                                                    <label>Titulo</label>
                                                    <input className="form-control" value={title} onChange={(e) => { this.setState({ editing: {...this.state.editing, title: e.target.value } }) }}></input>
                                                </div>
                                                <div className="form-group">
                                                    <label>Autor(es)</label>
                                                    <input className="form-control" value={author} onChange={(e) => { this.setState({ editing: {...this.state.editing, author: e.target.value } }) }}></input>
                                                </div>
                                                <div className="form-group">
                                                    <label>Serie/Programa</label>
                                                    <input className="form-control" value={category} onChange={(e) => { this.setState({ editing: {...this.state.editing, category: e.target.value } }) }}></input>
                                                </div>
                                                <div className="form-group">
                                                    <label>Imagen (Menor a 300px)</label>
                                                    <label for="img-p" style={{ display: 'block' }}>
                                                        <div className="imagen-select">
                                                            <img src={imagePreview || img} alt="Seleccione" className="img-fluid"></img>
                                                        </div>
                                                    </label>
                                                    <input id="img-p" style={{ display: 'none' }} ref={this.img_e} type='file' className="form-control" onChange={(e) => { this.selectImag(e.target.files,true); }}></input>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { this.setState({ showE: false }) }} disabled={loadingEditPodcast}>Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={this.editPodcast.bind(this)} disabled={loadingEditPodcast}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    async logout(e) {
        e.preventDefault();
        e.stopPropagation();
        Progress.show();
        try {
            await this.props.createRequest({
                requestParams: {
                  method: 'get',
                  url: `${process.env.REACT_APP_API_BASE}/${BACKEND.LOGOUT}`,
                },
                requestId: requestIds.LOGOUT,
            },true);
        } catch(error) {
          console.log('');
        } finally {
            localStorage.clear();
            Progress.hide();
            this.props.history.replace('/');
        }
    }

    render() {
        const { podcasts } = this.state;
        return (
            <div className="App" style={{ overflow: 'hidden' }}>
                <React.Fragment>
                    <header className="header">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                    <ul className="navbar-nav mr-auto">
                                        <NavLink to="/"><img src="/img/CIRCUITO-LIDER.png" alt="" className="img-fluid nav-logo" /></NavLink>
                                    </ul>
                                    <ul className="navbar-nav ml-auto">
                                        <li className="link-nav">
                                            <a href="#logout" onClick={this.logout.bind(this)}><i className="fa fa-user"></i>salir</a>
                                        </li>
                                    </ul>
                            </nav>
                        </div>
                    </header>
                    <ToastContainer autoClose={false} limit={4} />
                    <section className="header-section section-padding">
                        <div className="container-fluid">
                        <div className="row mx-auto">
                            <div style={{ width: '100%' }}>
                            <NavLink className="link-nav" to="/programacion" >Programación</NavLink>
                            <NavLink className="link-nav" to="/admin" >Podcast</NavLink>
                            <NavLink className="link-nav" to="/novedades">Novedades</NavLink>
                            </div>
                        </div>
                        </div>
                    </section>
                    <div className="section-padding section-podcasts text-center">
                        <h2 className="contact-title">Podcasts</h2>
                    </div>

                    <section className="section-padding">
                        <div className="row">
                            <div className="col-md-10 mx-auto" style={{ textAlign: 'left' }}>
                                <button className="btn btn-lg btn-add" onClick={this.openModal.bind(this)}>Agregar Nuevo</button>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-md-10 col-xs-12 mx-auto">
                                <Loader visible={this.state.loading} type="Oval" color="#E6750A" height={48} width={48}/>
                                <table className="table table-stripe table-responsive table-podcasts">
                                    <tbody>
                                        {podcasts.map(podcast => (
                                            <tr key={podcast.id}>
                                                    <td><img src={podcast.img} alt="podcast" className="podcast-img"></img></td>
                                                    <td style={{ textAlign: 'left' }}><label className="podcast-item-title">{podcast.title}</label></td>
                                                    <td><label className="podcast-item-author">{podcast.author}</label></td>
                                                    <td><label className="podcast-item-author">{podcast.category}</label></td>
                                                    <td><label className="podcast-item-duration">Duración: </label>{secondsToHHMMSS(podcast.duration)}</td>
                                                    <td><button className="btn btn-sm btn-primary" title="Editar" onClick={() => { this.setState({ editing: { ...podcast, imagePreview: null, fileImg: null }, showE: true }) }}><i className="fa fa-edit"></i></button><button className="btn btn-sm btn-danger" title="Eliminar" onClick={() => { this.deletePodcast(podcast) }}><i className="fa fa-trash-o"></i></button></td>
                                            </tr> 
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                    {this.showModal()}
                    {this.showModalEdit()}
                </React.Fragment>
            </div>
        )
    }
}

function secondsToHHMMSS(t) {
    if (t === undefined || isNaN(t)) return '-:--'
    var h = Math.floor(t / 3600)
    var m = Math.floor(t / 60) % 60
    var s = Math.floor(t % 60)
    return `${h ? h + ':' : ''}${h ? ('0' + m).slice(-2) : m}:${('0' + s).slice(
      -2
    )}`
  }

const mapStateToProps = (state) => {
    return {
        session: state.session,
    }
}

const mapDispatchToProps = {
    createRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);