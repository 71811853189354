import React, { Component } from 'react';
//import ReactPlayer from 'react-player';
import ReactHowler from 'react-howler';
import Slider from "react-slick";
import axios from "axios";
import Progress from 'react-progress-2';
import ReactGA from 'react-ga';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-progress-2/main.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Player from '../../components/Player/player';
import './Home.css';
import './responsive.css';
import './range.css';
import Equalizer from '../../components/Equalizer/equalizer';
import EqualizerHtml5 from '../../components/Equalizer/EqualizerHtml5';

const INTERVAL = 5000;

class Home extends Component {
  // This sound file may not work due to cross-origin setting
  //UA-167073185-1
  constructor(props){
    super(props);
    this.state = {
      playing: false,
      loading: false,
      loadingNews: false,
      equ: false,
      mute: false,
      volume: 0.5,
      old_volume: 0.5,
      selected: 1,
      radio_select:1,
      radios: [
          { url: 'https://laradiossl.online:8046/', name:'Líder 107.9 FM Barinas', img: 'lider-barinas.png', path: ';' },
          { url: 'https://laradiossl.online:8006/', name:'Líder 94.9 FM Caracas', img: 'lider-caracas.png', path: ';' },
          { url: 'https://laradiossl.online:8022/', name:'Líder 91.1 FM San Cristobal', img: 'lider-san-cristobal.png', path: ';' },
          { url: 'https://laradiossl.online:8024/', name:'Líder 92.3 FM Mérida', img: 'lider-merida.png', path: ';' },
          { url: 'https://laradiossl.online:8026/', name:'Líder 94.9 FM Barquisimeto', img: 'lider-barquisimeto.png', path: ';' },
          { url: 'https://cloud.intervalomedia.live:7017/', name:'Líder 94.7 FM Maracaibo', img: 'lider-maracaibo.png', path: ';' },
          { url: 'https://laradiossl.online:8122/', name:'Líder 100.1 FM El Vigía', img: 'lider1001.png', path: ';' },
          { url: 'https://laradiossl.online:8030/', name:'Líder 100.3 FM Puerto Ordaz', img: 'lider-puerto-ordaz.png', path: ';' },
          { url: 'https://laradiossl.online:8032/', name:'Líder 106.7 FM Valencia', img: 'lider-valencia.png', path: ';' },
          { url: 'https://laradiossl.online:8034/', name:'Líder 107.5 FM Puerto La Cruz', img: 'lider-lacruz.png', path: ';' },
          //{ url: 'https://lider1077.laradiossl.online:8036/', name:'Líder 107.7 FM Margarita', img: 'lider-margarita.png' },
  
      ],
      name: '',
      email: '',
      message: '',
      loadingMess: false,
      currentId: 1,
      status: {
        streamstatus: 1,
      },
      banners: [],
      news: [],
      //https://laradiossl.online:8024/stats?sid=1&json=1
    }
    this.streaming = React.createRef();
    this.programacion = React.createRef();
    this.podcasts = React.createRef();
    this.novedades = React.createRef();
    this.contactanos = React.createRef();
    this.innerNavBar = React.createRef();
    this.cancelToken = null;
    this.timeout = null;
    ReactGA.initialize('UA-167317013-1');
  }

  componentDidMount(){
    this.getProgramation();
    this.getNews();
    ReactGA.pageview(window.location.pathname);
    window.scrollTo(0, this.streaming.current.offsetTop - this.innerNavBar.current.clientHeight );
  }

  componentWillUnmount() {
    if(this.timeout) clearInterval(this.timeout);
  }

  getProgramation() {
      this.setState({ loadingProg: true });
      axios({
          method: "get",
          url: `${process.env.REACT_APP_API_BASE}/programation`,
          headers: { 
                      "content-type": "application/json",
                    },
      })
      .then(result => {
          console.log(result);
          if (result.data.status === 'success'){
              this.setState({ banners: result.data.programation });
          }
      })
      .catch(error => {
          console.log('error',error);
          this.setState({ error: error.message });
      }).finally(() => this.setState({ loadingProg: false }));
      
  }

  getNews() {
      this.setState({ loadingNews: true });
      axios({
          method: "get",
          url: `${process.env.REACT_APP_API_BASE}/news`,
          headers: { 
                      "content-type": "application/json",
                    },
      })
      .then(result => {
          console.log(result);
          if (result.data.status === 'success'){
              this.setState({ news: result.data.news, loadingNews: false });
          }
      })
      .catch(error => {
          console.log('error',error);
          this.setState({ error: error.message, loadingNews: false });
      });
  }

  onChangeRadio = (option) => {
    const { playing } = this.state;
    const newId = Math.ceil(Math.random()*3000);
    if (!this.player) {
      this.setState({ selected: option.target.value, currentId: newId, status: { streamstatus: 1 } });
      this.slider.slickGoTo(option.target.value);
      return;
    }
    this.player.howler.stop();
    this.player.howler.unload();
    this.setState({ selected: option.target.value, currentId: newId, status: { streamstatus: 1 } });
    this.slider.slickGoTo(option.target.value);
    if (playing) {
      ReactGA.event({
        category: "play streaming",
        action: this.state.radios[option.target.value].name,
      });
      this.initStatusChenckInterval(true);
    }
  }

  slideToRadio = (option) => {
    const { selected } = this.state;
    if (selected === option) return;

    const newId = Math.ceil(Math.random()*3000);

    if (this.player) {
      this.player.howler.stop();
      this.player.howler.unload();
    }
    this.setState({ playing: true, selected: option, currentId: newId, status: { streamstatus: 1 }, equ: false });
      ReactGA.event({
        category: "play streaming",
        action: this.state.radios[option].name,
      });
      this.initStatusChenckInterval(true);
  }

  stopRadio  = () => {
    this.setState({ equ: false, status: { streamstatus: 1 } });
    if (!this.player) return;
    this.player.howler.stop();
    this.player.howler.unload();
  }

  validateForm = (e) => {
    e.preventDefault();
    const { name, email, message } = this.state;
    let error = false;
    if (name.trim() === '' ) {
      toast.error('Ingrese un nombre', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000
      });
      error = true;
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      toast.error('Ingrese un email valido', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000
      });
      error = true;
    }
    if (message.trim() === '' ) {
      toast.error('Mensaje es requerido', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000
      });
      error = true;
    }

    if (error) return;
    this.handleFormSubmit();
  }

  handleFormSubmit = () => {
    const { name, email, message } = this.state;
    this.setState({ loadingMess: true });
    Progress.show();
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}`,
      headers: { "content-type": "application/json", "Access-Control-Allow-Origin": "*" },
      data: { name, email, message }
    })
      .then(result => {
        if (result.data.sent) {
          this.setState({ 
                            error: false, 
                            loadingMess: false,
                            name: '',
                            email: '',
                            message: '',
                        });
          toast.success('Enviado exitosamente', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          });
        } else {
          toast.error('No se pudo enviar, intente nuevamente', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
          });
          this.setState({ error: true, loadingMess: false });
        }
      })
      .catch(error => {
        this.setState({ error: error.message, loadingMess: false });
        toast.error('No se pudo enviar, intente nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
      })
      .finally(() => { Progress.hide(); });
  };

  initStatusChenckInterval = async(reset = false) => {
    if (reset) {
      await this.cancelCheck();
    }
    if (!this.timeout) {
      this.timeout = setInterval(this.checkStatus.bind(this),INTERVAL);
      this.checkStatus();
    }
  }

  checkStatus = () => {
    const { radios, selected, loading } = this.state;
    const rs = radios[selected];
    if (loading) return;
    this.setState({ loading: true });
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.cancelToken = source;
    //const base_url = encodeURIComponent(`${rs.url}stats?sid=1&json=1`);
    //const url = `http://app.circuitolider.com/api/proxy.php?csurl=${base_url}`;
    axios({
      method: "get",
      url: `${rs.url}stats?sid=1&json=1`,
      headers: { 
                  "content-type": "application/json",
                },
      cancelToken: source.token,
    })
      .then(result => {
        console.log(result);
        if(result.data){
          this.setState(prevState => ({ loading: false, status: { ...prevState.status, ...result.data } }));
        }
      })
      .catch(error => {
        console.log('error',error);
        this.setState({ error: error.message, loading: false });
      });
  }

  cancelCheck = async() => {
    if (this.timeout) {
      clearInterval(this.timeout);
      this.timeout = null;
    }
    if (this.cancelToken) {
      this.cancelToken.cancel('canceled');
      this.cancelToken = null;
    }
    await Promise.resolve(this.setState({ loading: false }));
  }

  changePlay = async() => {
    const { radios, selected, radio_select } = this.state;
    let rs = radios[selected];
    if (this.state.playing) {
      this.stopRadio(); 
      ReactGA.event({
        category: "stop streaming",
        action: rs.name,
      });
      await this.cancelCheck();
    } else {
      rs = radios[radio_select];
      ReactGA.event({
        category: "play streaming",
        action: rs.name,
      });
      this.setState({ selected: radio_select })
      this.initStatusChenckInterval();
    }
    
    this.setState({ playing: !this.state.playing, status: { streamstatus: 1 } })
  }

  mute = () => {
    const { mute, old_volume, volume } = this.state;
    this.setState({ mute: !mute, volume: mute ? old_volume : 0.0, old_volume: mute ? old_volume : volume });
  }

  highVolume = () => {
    const { mute, old_volume, volume } = this.state;
    const newvolume = mute ? old_volume + 0.1 : volume + 0.1;
    this.setState({ mute: false, volume: newvolume > 1.0 ? 1.0 : newvolume });
  }

  changeVolume = (value) => {
    const new_volume = parseFloat(value.target.value);
    this.setState({ volume: new_volume, mute: new_volume < 0.01 });
  }

  render () {
    const { volume, radios, selected, playing, equ, mute, currentId, status, radio_select, banners, news } = this.state;
    const rs = radios[selected];
    return (
      <div className="App">
        <Progress.Component
          style={{ background: '#99999978', height: '5px' }}
          thumbStyle={{ background: '#FAA819', height: '5px' }}
        />
        <ToastContainer autoClose={false} />
        <header className="header">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-light">
                        <ul className="navbar-nav mx-auto">
                          <img src="/img/CIRCUITO-LIDER.png" alt="" className="img-fluid nav-logo" />
                        </ul>
                </nav>
            </div>
        </header>
        <header ref={this.innerNavBar} className="header fixed-top">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-light">
                        <ul className="navbar-nav mx-auto">
                          <img src="/img/CIRCUITO-LIDER.png" alt="" className="img-fluid nav-logo" />
                        </ul>
                </nav>
            </div>
        </header>

        <section className="header-section section-padding">
            <div className="container-fluid">
              <div className="row mx-auto">
                <div style={{ width: '100%' }}>
                  <a className="link-nav" href="#streaming" onClick={(e) => { e.preventDefault(); window.scrollTo(0, this.streaming.current.offsetTop - this.innerNavBar.current.clientHeight )  }}>Streaming</a>
                  <a className="link-nav" href="#programacion" onClick={(e) => { e.preventDefault(); window.scrollTo(0, this.programacion.current.offsetTop - this.innerNavBar.current.clientHeight)  }}>Programación</a>
                  <a className="link-nav" href="#programacion" onClick={(e) => { e.preventDefault(); window.scrollTo(0, this.podcasts.current.offsetTop - this.innerNavBar.current.clientHeight)  }}>Podcast</a>
                  <a className="link-nav" href="#novedades" onClick={(e) => { e.preventDefault(); window.scrollTo(0, this.novedades.current.offsetTop - this.innerNavBar.current.clientHeight)  }}>Novedades</a>
                  <a className="link-nav" href="#contactanos" onClick={(e) => { e.preventDefault(); window.scrollTo(0, this.contactanos.current.offsetTop - this.innerNavBar.current.clientHeight)  }}>Contáctanos</a>
                </div>
              </div>
            </div>
        </section>
        <section ref={this.streaming} className="streaming-section section-padding">
            <div className="container-fluid">
              <h2 className="streaming-header">Streaming</h2>
              <div className="row align-items-center">
                  <div className="col col-xs-12 align-items-center">
                    <select className="radio-select" value={selected} onChange={(option) => { this.onChangeRadio(option) }}>
                      {radios.map((r,i) => (<option key={'option_'+i} value={i}>{r.name}</option>))}
                    </select>
                  </div>
              </div>

              <div className="row align-items-center">
                  <div className="col col-xs-12 align-items-center">
                    <div className="slider-radios">
                      <Slider 
                        ref={slider => (this.slider = slider)}
                        dots={false}
                        infinite={true}
                        slidesToShow={3}
                        centerMode={true}
                        initialSlide={1}
                        focusOnSelect={true}
                        arrows={false}
                        // fade: true,
                        swipe={true}
                        beforeChange={(currentSlide, nextSlide) => {
                          this.slideToRadio(nextSlide);
                          this.setState({ radio_select: nextSlide });
                        }}
                        responsive={[
                          {
                            breakpoint: 600,
                            settings: {
                              slidesToShow: 3,
                              slidesToScroll: 1,
                              initialSlide: 1,
                              variableWidth: true,
                              adaptiveHeight: true
                            }
                          },
                          {
                            breakpoint: 800,
                            settings: {
                              slidesToShow: 3,
                              slidesToScroll: 1,
                              initialSlide: 1,
                              variableWidth: true,
                              adaptiveHeight: true
                            }
                          }]}
                      >
                        {radios.map((r,i) => (
                          <div key={'slide_'+i} className={i === radio_select ? 'item-slider active' : 'item-slider'}>
                            <img src={"/img/radios/"+r.img} alt={r.name} className="img-fluid radios-img" onDoubleClick={() => { this.slideToRadio(radio_select) }}/>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
              </div>
              {/*<ReactPlayer url={rs.url+'stream'} playing={playing} controls />*/}
              
              <div className="row align-items-center">
                  <div className="col col-xs-12 align-items-center">
                    <EqualizerHtml5 
                      active={playing && equ}
                    >
                      <Equalizer active={playing && equ} />
                      <span className="separator"></span>
                      <Equalizer active={playing && equ} />
                    </EqualizerHtml5>
                  </div>
              </div>
              {(status.streamstatus === 0 ) && (<h5 className="error-stream">Streaming no disponible</h5>)}
              {playing && (
                <ReactHowler
                  key={rs.url+currentId}
                  src={rs.url+rs.path}
                  format={['mp3','aac']}
                  playing={playing}
                  html5={true}
                  volume={volume}
                  onLoadError={(error) => { this.setState({ status: { streamstatus: 0 }, equ:false }); }}
                  onStop={() => { this.setState({ equ: false }) }}
                  onLoad={() => { this.setState({ equ: true, status: { streamstatus: 1 } }) }}
                  mute={mute}
                  ref={(ref) => (this.player = ref)}
                />
              )}
              <div className="row align-items-center">
                  <div className="col col-xs-12 align-items-center">
                    <button 
                      className="btn btn-play" 
                      onClick={() => { this.changePlay() }}
                    >
                      <img src={playing ? '/img/icons/pausa.svg' : '/img/icons/play.svg' } alt="mute" />
                    </button>
                  </div>
              </div>
              <div className="row align-items-center">
                <div className="col col-xs-12 align-items-center">
                  <div className="player-controls">
                    <img src={"/img/icons/mudo.svg"} style={{ width: 20, height:20, marginRight: 10 }} alt="mute" onClick={this.mute.bind(this)} />
                    <input type="range" min={0} max={1} step={0.01} value={volume} onChange={this.changeVolume.bind(this)} />
                    <img src={"/img/icons/volumen.svg"} style={{ width: 20, height:20,  marginLeft: 10 }} onClick={this.highVolume.bind(this)} alt="mute" />
                  </div>
                </div>
              </div>

            </div>
        </section>

        <section className="section-padding">
          <div className="banner">
            <div className="col-xs-12">
              <h1 className="banner-title">+ Radio</h1>
              <h1 className="banner-title-sub">+ Música</h1>
            </div>
          </div>
        </section>


        <div ref={this.programacion} className="section-title-programation text-center">
          <h2 className="contact-title">Programación</h2>
        </div>


        <section className="programation-up-section section-padding">
            <div className="container-fluid align-items-center">
              <div className="news-slider">
                <Slider 
                  autoplay={true}
                  autoplaySpeed={10000}
                  dots={true}
                  infinite={true}
                  slidesToShow={1}
                  initialSlide={0}
                  focusOnSelect={true}
                  arrows={true}
                  swipe={true}
                  responsive={[
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 0,
                        dots: false
                      }
                    },
                  ]}
                >
                  {this.state.loadingProg ? (
                    <Loader visible={this.state.loadingProg} type="Oval" color="#E6750A" height={48} width={48}/>
                  ) : (
                    banners.map((banner,i) => (
                      <div key={'news_'+i} className="item-img-news">
                        <img src={banner.url} alt={banner.name} className="img-fluid img-news" />
                      </div>
                    ))
                  )}
                  
                </Slider>
              </div>

            </div>
        </section>

        <div ref={this.podcasts} className="section-title-news text-center">
          <h2 className="contact-title">Podcasts</h2>
        </div>

        <section className="section-padding">
            <Player />
        </section>

        <div ref={this.novedades} className="section-title-programation text-center">
          <h2 className="contact-title">Novedades</h2>
        </div>


        <section className="news-up-section section-padding">
            <div className="container-fluid align-items-center">
              <div className="news-slider">
                <Slider 
                  autoplay={true}
                  autoplaySpeed={10000}
                  dots={true}
                  infinite={true}
                  slidesToShow={1}
                  initialSlide={0}
                  focusOnSelect={true}
                  arrows={true}
                  swipe={true}
                  responsive={[
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 0,
                        dots: false
                      }
                    },
                  ]}
                >
                {this.state.loadingNews ? (
                  <Loader visible={this.state.loadingNews} type="Oval" color="#E6750A" height={48} width={48}/>
                ) : (
                  news.map((banner,i) => (
                    <div key={'news_'+i} className="item-img-news">
                      <img src={banner.url} alt={banner.name} className="img-fluid img-news" />
                    </div>
                  ))
                )}
                  
                </Slider>
              </div>

            </div>
        </section>

        <section  ref={this.contactanos} className="contact-section section-padding">
          <div className="container-fluid align-items-center">
            <div className="section-title text-center">
              <h2 className="contact-title">Contáctanos</h2>
            </div>
            <div className="contact-form-group">
              <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                        <div style={{ textAlign: 'left' }}>
                          <span className="contact-font">Escribenos</span>
                        </div>
                        <form className="contact-form">
                            <div className="row">
                                <div className="col col-xs-12">
                                    <input type="text" placeholder="Nombre" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                                </div>
                            </div>
                            <div className="row">
                              <div className="col col-xs-12">
                                <input type="email" placeholder="Email" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }}  />
                              </div>
                            </div>
                            <div className="row">
                                <div className="col col-xs-12">
                                    <textarea rows="5" placeholder="Mensaje" value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }) }} ></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-xs-12">
                                    <button type="submit" className="btn theme-btn" disabled={this.state.loadingMess} onClick={this.validateForm.bind(this)}>Enviar</button>
                                </div>
                            </div>
                        </form>
                      </div>
                  </div>
                </div>
              <div className="col-xs-12 col-sm-6 col-md-6">
                <div className="row">
                  <div className=" col-xs-12 col-sm-12 col-md-12 right-contact">
                    <p>También puedes escribirnos <img src="/img/icons/iconmonstr-whatsapp-1.svg" alt="whatsapp" style={{ width: 15, height: 15, marginLeft: 5, marginRight: 5 }}></img>:</p>
                    <p><a href="https://wa.me/0584241000949" target="_blank" rel="noopener noreferrer">(0424) 100 09 49 Caracas</a></p>
                    <p><a href="https://wa.me/0584242995967" target="_blank" rel="noopener noreferrer">(0424) 299 59 67 Valencia</a></p>
                    <p><a href="https://wa.me/05804143200923" target="_blank" rel="noopener noreferrer">(0414) 320 09 23 Mérida</a></p>
                    <p><a href="https://wa.me/05804141000949" target="_blank" rel="noopener noreferrer">(0414) 100 09 49 Barquisimeto</a></p>
                    <br/><br/>
                    <p>Síguenos en:</p>
                    <div className="socials-icons">
                      <a href="https://instagram.com/circuitoliderfm" target="_blank" rel="noopener noreferrer">
                        <img src={"/img/icons/Instagram_2.png"} alt="instagram" className="img-fluid icons-socials" />
                      </a>
                      <a href="https://twitter.com/circuitoliderfm" target="_blank" rel="noopener noreferrer">
                        <img src={"/img/icons/Twitter_2.png"} alt="Twitter" className="img-fluid icons-socials" />
                      </a>
                      <a href="https://tiktok.com/@circuitoliderfm" target="_blank" rel="noopener noreferrer">
                        <img src={"/img/icons/TikTok_2.png"} alt="TikTok_" className="img-fluid icons-socials" />
                      </a>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      
        <section className="section-padding">
          <div className="banner-mk">
          </div>
        </section>
      </div>
    );
  }
}

export default Home;